
import { getAction,getByIdAction,postAction,putAction,getByTypeAction,uploadFile } from '@/api/common/request'
const baseURL = 'xun/work/xunSalary'
//调用 import { borrowList } from "@/api/work/salary/salary";

//列表
const listApi = (params) => getAction(baseURL+"/list", params);
//新增
const addApi = (params)=>postAction(baseURL+'/add',params);
//编辑
const editApi=(params)=>postAction(baseURL+'/edit',params);
//详情
const selectSalaryById=(params)=>getByIdAction(baseURL+'/selectSalaryById',params);
//归档
const selecFinIsh=(params)=>getByIdAction(baseURL+'/selecFinIsh',params);

const importSalaryDataApi=(params)=>uploadFile(baseURL+"/importSalaryData",params)


export{
    listApi,
    addApi,
    editApi,
    selecFinIsh,
    selectSalaryById,
    importSalaryDataApi
}
