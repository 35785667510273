<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
    <span class="dialog-footer">
      <el-button type="primary" @click="saveSumbit()">保 存</el-button>
      <label
          for="upload"
          class="custom-upload-button"
        >
          <button type="button">上传文件</button>
          <input
            id="upload"
            type="file"
            ref="fileInput"
            @change="handleExcelFile"
          />
        </label>
      <el-button type="primary" @click="cancel()">关 闭</el-button>
    </span>
    <el-divider></el-divider>
    <el-form v-model="addFrom" label-width="100px" ref="addFrom">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="所属公司" width="120px" prop="companyId">
            <el-select
              disabled
              @change="AddSelectDept"
              v-model="companyFrom"
              filterable
              placeholder="请选择"
              style="width:70%"
            >
              <el-option
                v-for="item in companyIdList"
                :key="item.uniqueId"
                :label="item.companyName"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="记录月份" width="120px" prop="recordData">
            <el-date-picker
              v-model="addFrom.recordData"
              align="right"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :disabled="editDist"
            ></el-date-picker>
            <!-- disabled -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工资月份" width="120px" prop="salartData">
            <el-date-picker
              v-model="addFrom.salartData"
              align="right"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :disabled="editDist"
            ></el-date-picker>
            <!-- disabled -->
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="基本工资" width="120px" prop="primaryMoney">
            <el-input-number
              width="20px"
              v-model="addFrom.primaryMoney"
              @change="countPayrollMoney"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="岗位工资" width="120px" prop="postMoney">
            <el-input-number width="20px" v-model="addFrom.postMoney" @change="countPayrollMoney" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="应发工资" width="120px" prop="payrollMoney">
            <el-input-number width="20px" v-model="addFrom.payrollMoney" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="实发工资" width="120px" prop="realizedMoney">
            <el-input-number width="20px" v-model="addFrom.realizedMoney" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="总共收入" width="120px" prop="countMoney">
            <el-input-number width="20px" v-model="addFrom.countMoney" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="addFrom.remark" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-tabs v-model="activeName">
      <el-tab-pane label="扣税部分" name="first" :key="'first'">
        <el-form v-model="addFrom" label-width="100px" ref="addFrom">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="总扣税" width="80px" prop="deduction">
                <el-input width="20px" v-model="addFrom.deduction" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="综合税" prop="androiditTax">
                <el-popover placement="right" width="700" trigger="click">
                  <el-table :data="xunSalaryInfoFromList">
                    <el-table-column label="类型" align="center" fixed width="100">
                      <template slot-scope="scope">
                        <el-form :model="scope.row">
                          <el-tag v-if="scope.row.type=='xun.personal.pension'">养老金基数</el-tag>
                          <el-tag v-if="scope.row.type=='xun.personal.injury'">工伤基数</el-tag>
                          <el-tag v-if="scope.row.type=='xun.personal.unemployment'">事业基数</el-tag>
                          <el-tag v-if="scope.row.type=='xun.personal.medical'">医疗基数</el-tag>
                          <el-tag v-if="scope.row.type=='xun.personal.birth'">生育基数</el-tag>
                          <el-tag v-if="scope.row.type=='xun.personal.accum'">公积金基数</el-tag>
                          <el-tag v-if="scope.row.type=='xun.personal.major'">重大医疗基数</el-tag>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column label="公司金额" align="center" width="200">
                      <template slot-scope="scope">
                        <el-form :model="scope.row">
                          <el-form-item>
                            <el-input-number
                              v-model="scope.row.companyRationBase"
                              @change="countMoney(scope.row)"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column label="个人金额" align="center" width="200">
                      <template slot-scope="scope">
                        <el-form :model="scope.row">
                          <el-form-item>
                            <el-input-number
                              v-model="scope.row.myRationBase"
                              @change="countMoney(scope.row)"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column label="总金额" align="center" width="200">
                      <template slot-scope="scope">
                        <el-form :model="scope.row">
                          <el-form-item>
                            <el-input-number
                              v-model="scope.row.baseRation"
                              @change="countMoney(scope.row)"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button width="20px" slot="reference">{{addFrom.androiditTax}}</el-button>
                </el-popover>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="个税" width="120px" prop="personalTax">
                <el-input-number
                  width="20px"
                  v-model="addFrom.personalTax"
                  @change="countDeduction"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="其他扣税" width="120px" prop="othersTax">
                <el-input-number width="20px" v-model="addFrom.othersTax" @change="countDeduction" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="党费" width="120px" prop="partyTax">
                <el-input-number width="20px" v-model="addFrom.partyTax" @change="countDeduction" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="副业税" width="120px" prop="sidelineTax">
                <el-tooltip class="item" effect="dark" content="不会加入到实发中" placement="top-start">
                  -->
                  <i class="el-icon-question" />
                </el-tooltip>
                <el-input-number
                  width="20px"
                  v-model="addFrom.sidelineTax"
                  @change="countDeduction"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="补贴部分" name="subsidy" :key="'subsidy'">
        <el-form v-model="addFrom" label-width="100px" ref="addFrom">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="总补贴" width="120px" prop="AllSubsidy">
                <el-input-number
                  width="20px"
                  v-model="addFrom.AllSubsidy"
                  @change="countRealizedMoney"
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="电脑补贴" width="120px" prop="computerSubsidy">
                <el-input-number
                  width="20px"
                  v-model="addFrom.computerSubsidy"
                  @change="countAllSubsidy"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="交通补贴" width="120px" prop="trafficSubsidy">
                <el-input-number
                  width="20px"
                  v-model="addFrom.trafficSubsidy"
                  @change="countAllSubsidy"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="餐补" width="120px" prop="mealSubsidy">
                <el-input-number
                  width="20px"
                  v-model="addFrom.mealSubsidy"
                  @change="countAllSubsidy"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="住宿补贴" width="120px" prop="staySubsidy">
                <el-input-number
                  width="20px"
                  v-model="addFrom.staySubsidy"
                  @change="countAllSubsidy"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="出差补贴" width="120px" prop="evectionSubsidy">
                <el-input-number
                  width="20px"
                  v-model="addFrom.evectionSubsidy"
                  @change="countAllSubsidy"
                />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="项目补贴" width="120px" prop="projectSubsidy">
                <el-input-number
                  width="20px"
                  v-model="addFrom.projectSubsidy"
                  @change="countAllSubsidy"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="额外补贴" width="120px" prop="extraSubsidy">
                <el-input-number
                  width="20px"
                  v-model="addFrom.extraSubsidy"
                  @change="countAllSubsidy"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="加班补贴" width="120px" prop="workSubsidy">
                <el-input-number
                  width="20px"
                  v-model="addFrom.workSubsidy"
                  @change="countAllSubsidy"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话补贴" width="120px" prop="phoneSubsidy">
                <el-input-number
                  width="20px"
                  v-model="addFrom.phoneSubsidy"
                  @change="countAllSubsidy"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="其他部分" name="second" :key="'second'">
        <el-form v-model="addFrom" label-width="100px" ref="addFrom">
          <el-row :gutter="24">
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="其他总和" width="120px" prop="otherAll">
                  <el-input-number
                    width="20px"
                    v-model="addFrom.otherAll"
                    @change="countRealizedMoney"
                    disabled
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="副业收入" width="120px" prop="sidelineMoney">
                  <el-tooltip class="item" effect="dark" content="不会加入到实发中" placement="top-start">
                    -->
                    <i class="el-icon-question" />
                  </el-tooltip>
                  <el-input-number
                    width="20px"
                    v-model="addFrom.sidelineMoney"
                    @change="countOtherAll"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="副业备注" width="120px" prop="sidelineRemark">
                  <el-input width="20px" v-model="addFrom.sidelineRemark" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-col :span="8">
              <el-form-item label="其他收入" width="120px" prop="otherMoney">
                <el-tooltip class="item" effect="dark" content="不会加入到实发中" placement="top-start">
                  -->
                  <i class="el-icon-question" />
                </el-tooltip>
                <el-input-number width="20px" v-model="addFrom.otherMoney" @change="countOtherAll" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="额外收入" width="120px" prop="extraMoney">
                <el-input-number width="20px" v-model="addFrom.extraMoney" @change="countOtherAll" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="额外收入备注" width="120px" prop="extraRemark">
                <el-input width="20px" v-model="addFrom.extraRemark" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="奖金" width="120px" prop="bonus">
                <el-input-number width="20px" v-model="addFrom.bonus" @change="countOtherAll" />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="绩效" width="120px" prop="perfMoney">
                <el-input-number width="20px" v-model="addFrom.perfMoney" @change="countOtherAll" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="绩效备注" width="120px" prop="perfRemark">
                <el-input width="20px" v-model="addFrom.perfRemark" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import { addApi, selectSalaryById, editApi,importSalaryDataApi } from "@/api/work/salary/salary";
import { listcompanyApi } from "@/api/my/company/company";
import { getDataListApi } from "@/api/my/company/companysalaryconfigs";
import { selectcompanytreatmentByIdApi } from "@/api/my/company/companytreatment";

export default {
  name: "AddSalary",
  data() {
    return {
      dialogVisible: false,
      addFrom: {
        recordData: this.dayjs(this.dayjs()).format("YYYY-MM-DD"),
        salartData: this.dayjs(this.dayjs().subtract(1, "month")).format(
          "YYYY-MM-DD"
        ),
        androidit: 0,
        depositFund: 0,
        providentFund: 0,
        primaryIncome: 0,
        othersDeduction: 0,
        trafficMoney: 0,
        mealMoney: 0,
        bonus: 0,
        computerMoney: 0,
        projectMoney: 0,
        perfMoney: 0,
        extraIncome: 0,
        realizedIncome: 0,
        deduction: 0,
        androiditTax: 0,
        personalTax: 0,
        othersTax: 0,
        partyTax: 0,
        sidelineTax: 0,
        AllSubsidy: 0,
        computerSubsidy: 0,
        trafficSubsidy: 0,
        mealSubsidy: 0,
        staySubsidy: 0,
        evectionSubsidy: 0,
        projectSubsidy: 0,
        extraSubsidy: 0,
        workSubsidy: 0,
        phoneSubsidy: 0,
        otherAll: 0,
        sidelineMoney: 0,
        otherMoney: 0,
        extraMoney: 0,
        bonus: 0,
        perfMoney: 0,
        realizedMoney: 0,
        primaryMoney: 0,
        postMoney: 0,
        payrollMoney: 0,
        countMoney: 0
      },
      editDist: false,
      activeName: "first",
      companyIdList: [],
      xunSalaryInfoFromList: [],
      companyFrom: {},
      caType: 1
    };
  },
  methods: {
    //打开弹窗
    show(id, type) {
      if (type == 2) {
        this.selectSalaryByIdButton(id);
        this.selectcompanytreatmentButton()
        this.editDist = true;
      }
      if (type == 1) {
        this.getAndroiditTaxData();
      }
      this.listcompanyButton();
      this.dialogVisible = true;
      this.caType = type;
    },
    listcompanyButton() {
      listcompanyApi({ companyStatus: 2 }).then(res => {
        this.companyIdList = res.result.records;
        if (res.result.records.length !== 0) {
          this.addFrom.companyId = res.result.records[0].uniqueId;
          this.addFrom.companyName = res.result.records[0].companyName;
          this.addFrom.bankInfoId = res.result.records[0].bankId;
        }
      });
    },
    selectSalaryByIdButton(id) {
      selectSalaryById(id).then(res => {
        this.addFrom = res.result;
        this.xunSalaryInfoFromList = res.result.xunSalaryReqInfoListVO;
        this.countAllSubsidy();
        this.countRealizedMoney();
        this.countOtherAll();
      });
    },
    //
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.dialogVisible = false;
      // this.addFrom = {};
      this.$refs["addFrom"].resetFields();
      this.$parent.getSalaryList();
    },
    //关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
        })
        .catch(_ => {});
    },
    //保存
    saveSumbit() {
      this.addFrom.xunSalaryReqInfoListVO = this.xunSalaryInfoFromList;
      if (this.caType == 1) {
        addApi(this.addFrom).then(res => {
          this.cancel();
        });
      }
      if (this.caType == 2) {
        editApi(this.addFrom).then(res => {
          this.cancel();
        });
      }
    },
    //获取公司
    AddSelectDept(val) {
      this.addFrom.companyId = val.uniqueId;
      this.addFrom.userCompany = val.companyName;
      this.addFrom.bankInfoId = val.bankId;
    },
    //总扣税 this.addFrom.deduction
    countDeduction() {
      console.log(
        this.addFrom.androiditTax,
        this.addFrom.personalTax,
        this.addFrom.othersTax,
        this.addFrom.partyTax,
        this.addFrom.sidelineTax
      );
      this.addFrom.deduction =
        this.addFrom.androiditTax +
        this.addFrom.personalTax +
        this.addFrom.othersTax +
        this.addFrom.partyTax +
        this.addFrom.sidelineTax;
      this.countRealizedMoney();
    },
    //总补贴  this.addFrom.AllSubsidy
    countAllSubsidy() {
      this.addFrom.AllSubsidy =
        this.addFrom.computerSubsidy +
        this.addFrom.trafficSubsidy +
        this.addFrom.mealSubsidy +
        this.addFrom.staySubsidy +
        this.addFrom.evectionSubsidy +
        this.addFrom.projectSubsidy +
        this.addFrom.extraSubsidy +
        this.addFrom.workSubsidy +
        this.addFrom.phoneSubsidy;
      this.countRealizedMoney();
    },
    //其他收入 this.addFrom.otherAll
    countOtherAll() {
      this.addFrom.otherAll =
        this.addFrom.sidelineMoney +
        this.addFrom.otherMoney +
        this.addFrom.extraMoney +
        this.addFrom.bonus +
        this.addFrom.perfMoney;
      this.countRealizedMoney();
    },
    //应发
    countPayrollMoney() {
      this.addFrom.payrollMoney =
        this.addFrom.primaryMoney + this.addFrom.postMoney;
      this.countRealizedMoney();
    },
    //实发
    countRealizedMoney() {
      this.addFrom.realizedMoney =
        this.addFrom.payrollMoney +
        this.addFrom.AllSubsidy +
        this.addFrom.otherAll -
        this.addFrom.deduction -
        this.addFrom.sidelineMoney +
        this.addFrom.sidelineTax -
        this.addFrom.otherMoney;
      this.countMoneyAll();
    },
    //总共
    countMoneyAll() {
      this.addFrom.countMoney =
        this.addFrom.realizedMoney +
        this.addFrom.sidelineMoney -
        this.addFrom.sidelineTax +
        this.addFrom.otherMoney;
    },
    //获取配置好的税模板
    getAndroiditTaxData() {
      getDataListApi().then(res => {
        this.xunSalaryInfoFromList = res.result;
        this.countAndroiditTax();
      });
    },
    //计算综合税的各明细
    countMoney(row) {
      row.baseRation = row.companyRationBase + row.myRationBase;
      row.editData=true;
      this.countAndroiditTax();
    },
    //汇总综合税
    countAndroiditTax() {
      let res = this.xunSalaryInfoFromList.reduce((sumData, key) => {
        return sumData + key.baseRation;
      }, 0);
      this.addFrom.androiditTax = res;
      this.countDeduction();
    },
    selectcompanytreatmentButton() {
      selectcompanytreatmentByIdApi().then(res => {
        
      });
    },
    handleExcelFile(event) {
      const file = event.target.files[0];
      console.log("=-", file);
      importSalaryDataApi(file).then(res=>{
        console.log('reslut',res)
        this.addFrom.countMoney=res.result.countMoney;
        this.addFrom.payrollMoney=res.result.payrollMoney;
        this.xunSalaryInfoFromList = res.result.xunSalaryReqInfoListVO;
      })
    },
  }
};
</script>
<style lang="less" scoped>

.custom-upload-button {
  display: inline-block;
  overflow: hidden;
  position: relative;
  margin: -15px 20px;
}

.custom-upload-button button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.custom-upload-button input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

</style> 