import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunCompanySalaryConfigs'


const listcompanysalaryconfigsApi =(params)=>getAction(baseURL+"/list",params);

const addcompanysalaryconfigsApi=(params)=>postAction(baseURL+"/add",params);


const saveCompanysalaryconfigsApi=(params)=>postAction(baseURL+"/save",params);



const editcompanysalaryconfigsApi=(params)=>putAction(baseURL+"/edit",params);

const deletecompanysalaryconfigsByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectcompanysalaryconfigsByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);


const getDataListApi=(params)=>getAction(baseURL+'/getDataList',params);

export{
    selectcompanysalaryconfigsByIdApi,
    deletecompanysalaryconfigsByIdApi,
    editcompanysalaryconfigsApi,
    addcompanysalaryconfigsApi,
    listcompanysalaryconfigsApi,
    getDataListApi,
    saveCompanysalaryconfigsApi
}
